/* eslint-disable max-len */
import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
  RISK_SECTION_VERY_HIGH,
  RISK_SECTION_VERY_LOW,
} from '@consts/risk';
import {
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_VERY_LOW, 'common.very_low', 'risk.very_low', 0, 0),
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 1, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 3, 5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 6, 9),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 10, 99999),
];

export const resultSettings = [
  formatResult(
    'With early treatment, you can speed up your recovery and reduce any risk of developing increased problems. At Joint Academy, you get assessment and treatment without the wait.',
    'Based on your answers, your shoulder pain causes you mild discomfort in your everyday life. However, it’s always good to get an assessment of your pain in good time; the development of many joint pain conditions can be slowed down in an early stage, and much can be done to proactively reduce the risk of increased problems. The right treatment early on will also give you the best chance to maintain an active and pain-free life. Therefore, we recommend that you seek care for an assessment and treatment.<br/><br/>Today, specific exercise paired with education to help you better understand your condition is recommended as the first-line treatment for shoulder pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven shoulder treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your shoulder pain and restore mobility. Your exercises only take 5 minutes a day.',
    0,
    2,
  ),
  formatResult(
    'But don\'t worry! Treating your shoulder pain in time will reduce the risk of long-term problems. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your shoulder pain causes you moderate discomfort and appears to have a negative impact on your everyday life. With the right care, you can feel better and get back to doing what you love. Starting treatment early will avoid further stress on your joints, and help you to reduce your pain faster. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, specific exercise paired with education to help you better understand your condition is recommended as the first-line treatment for shoulder pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven shoulder treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your shoulder pain and restore mobility. Your exercises only take 5 minutes a day.',
    3,
    5,
  ),
  formatResult(
    'But don\'t worry! There are ways to treat shoulder pain that can get you back to doing what you love. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your shoulder pain causes you a high degree of discomfort and the risk of having long-term problems is high. With the right care, you can feel better and get back to everyday life with less pain and more mobility. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, specific exercise, paired with education to help you better understand your condition, is recommended as the first-line treatment for shoulder pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven shoulder treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your shoulder pain and restore mobility. Your exercises only take 5 minutes a day.',
    6,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Has your shoulder pain affected your daily activities at home and/or at work the last two weeks?',
  ),
  formatYesNoQuestion(
    'question2',
    'Has your shoulder pain prevented you from sleeping the last two weeks?',
  ),
  formatYesNoQuestion(
    'question3',
    'Have you felt little interest or pleasure in doing things the last two weeks?',
  ),
  formatYesNoQuestion(
    'question4',
    'Have you felt down, depressed or hopeless the last two weeks?',
  ),
  formatYesNoQuestion(
    'question5',
    'Has your shoulder pain prevented you from living a normal life the last two weeks?',
  ),
  formatYesNoQuestion(
    'question6',
    'Has your shoulder pain stopped you from socializing with your friends or family members as often as usual the last two weeks?',
  ),
  formatYesNoQuestion(
    'question7',
    'Have you had your problem for at least 2-3 weeks without any decrease in your neck pain?',
  ),
  formatYesNoQuestion(
    'question8',
    'Do you feel it’s unsafe for a person with a condition like yours to be physically active?',
  ),
  formatYesNoQuestion(
    'question9',
    'Do you think your pain condition will last a long time?',
  ),
];

export const references = [
  'Wertli MM, Rasmussen-Barr E, Weiser S, Bachmann LM, Brunner F. The role of fear avoidance beliefs as a prognostic factor for outcome in patients with nonspecific low back pain: a systematic review [published correction appears in Spine J. Aug 1;14(8):a18]. Spine J. 2014;14(5):816-36.e4. doi:10.1016/j.spinee.2013.09.036',
  'Cohen SP, Vase L, Hooten WM. Chronic pain: an update on burden, best practices, and new advances. Lancet. 2021;397(10289):2082-2097. doi:10.1016/S0140-6736(21)00393-7',
  'Luque-Suarez A, Martinez-Calderon J, Falla D. Role of kinesiophobia on pain, disability and quality of life in people suffering from chronic musculoskeletal pain: a systematic review. Br J Sports Med. 2019;53(9):554-559. doi:10.1136/bjsports-2017-098673',
  'Burgess R, Mansell G, Bishop A, Lewis M, Hill J. Predictors of functional outcome in musculoskeletal healthcare: An umbrella review. Eur J Pain. 2020;24(1):51-70. doi:10.1002/ejp.1483',
  'Vlaeyen JWS, Linton SJ. Fear-avoidance and its consequences in chronic musculoskeletal pain: a state of the art. Pain. 2000;85(3):317-332. doi:10.1016/S0304-3959(99)00242-0',
];
